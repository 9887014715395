import './App.css';

function App({ callback }) {
  return (
    <div className='App' onClick={callback}>
      <header className='App-header'>
        <p>Klicken Sie an eine beliebige Stelle um weitergeleitet zu werden.</p>
      </header>
    </div>
  );
}

export default App;
