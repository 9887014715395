import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

const redirect = () => window.location.replace('https://yona.app/visitar');
redirect();

ReactDOM.render(
  <React.StrictMode>
    <App callback={redirect} />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
